.App {
    text-align: center;
    max-width: 1120px;
    margin: 0 auto;
    position: relative;
    min-height: 110vh;
}

.App-logo {
    height: 100%;
    width: 35%;
    pointer-events: none;
}

.App-header {
    background-color: #fff;
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
}

.App-link {
    color: #61dafb;
}

.ant-spin-dot-item {
    background-color: #ee7f23;
}

.ant-divider-inner-text {
    font-weight: bold;
}
